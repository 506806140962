import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  useNavigate
  // , useLocation
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { basicSearchCount, resetVibeData, setCountLimit, setCurrentPage, setVibeMode } from '../../redux/slice/vibeset';
import guidedHome from '@/assets/images/guided_home.png';
import quickHome from '@/assets/images/quick_home.png';
import { useTranslation } from 'react-i18next';
// import { setClickedRoute, setRedirectedRoute } from '../../redux/slice/clickedRoute';

import { GUIDED_MIX, GUIDED_MIX_HOME_PAGE, HOME_PAGE, LIST_GUIDED, LIST_NATURAL, QUICK_MIX, QUICK_MIX_HOME_PAGE, SUBSCRIPTION_PLANS } from '../../constants/PageName';
import { setDemoUsedFlag, setVibeForFreeFlag } from '../../redux/slice/login';
import { showToast } from '../../redux/slice/toastSlice';

function ChooseAdventure() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const data = useSelector((state) => state?.login);
  const dataCheck = useSelector((state) => state);
  // const location = useLocation();

  // Function to handle setting vibe mode
  const setModeHandler = (mode) => {
    if (dataCheck?.login?.loginCred?.subscription_details?.is_basic_plan ? dataCheck?.login?.loginCred?.subscription_details?.is_basic_plan && dataCheck?.vibeset?.countLimit < 15 : true) {
      dispatch(setVibeMode(mode));
      if (mode == QUICK_MIX) {
        dispatch(setCurrentPage(LIST_NATURAL));
        dispatch(resetVibeData(mode));
        navigate(QUICK_MIX_HOME_PAGE);
      } else {
        dispatch(resetVibeData(mode));
        dispatch(setCurrentPage(LIST_GUIDED));
        navigate(GUIDED_MIX_HOME_PAGE);
      }
    }
    // else {
    //   navigate(SUBSCRIPTION_PLANS);
    //   dispatch(
    //     showToast({
    //       message: "You've reached your limit on the basic plan. Upgrade pro to continue.",
    //       type: 'error'
    //     })
    //   );
    // }
  };
  useEffect(() => {
    if (dataCheck?.login?.loginCred) {
      dispatch(basicSearchCount())
        .then((response) => {
          dispatch(setCountLimit(response?.payload?.count));
        })
        .catch((error) => {
          dispatch(
            showToast({
              message: 'ERROR',
              type: 'error'
            })
          );
        });
    }
    if (data?.demoUsed && data?.vibeForFreeFlag) {
      dispatch(setDemoUsedFlag(false));
      dispatch(setVibeForFreeFlag(false));
    }
    dispatch(resetVibeData(HOME_PAGE));
  }, []);

  return (
    <div className='container mx-auto px-4'>
      <Box className='child-main choose-adventure fade-in one'>
        <div className='center'>
          <Box className='centerright'>
            <Box className='bluetape_cya'></Box>
            <Typography variant='h1' component='h1' className='ish1 text-upper'>
              {t('common.letsVibe')}
            </Typography>
            <Typography variant='h2' component='h2' className='ish1below  text-white'>
              {t('choose-adventure.setlist')}
            </Typography>
          </Box>
          <Box className='sideBlueBoxes'>
            <Box className='background-1'>
              <Button disableRipple href='' onClick={() => setModeHandler(QUICK_MIX)} underline='none' className='code'>
                <Box className='category-box-container'>
                  <Box>
                    <p className='isTextLargeMono'>{t('header.mixMode')}</p>
                  </Box>
                  <Box className='is-txt-small'>
                    <span>
                      {t('choose-adventure.generateTrack')}
                      <br />
                    </span>
                  </Box>
                  <ArrowForwardIcon className='text-white right-arrow' />
                </Box>
                <Box className='category-box-container-background'>
                  <img src={quickHome} alt={t('common.alt.vibeset')} />
                </Box>
              </Button>
            </Box>
            <Box className='background-2'>
              <Button disableRipple onClick={() => setModeHandler(GUIDED_MIX)} underline='none' className='code content-adventure'>
                <Box className='category-box-container'>
                  <Box>
                    <p className='isTextLargeMono'>{t('header.companinonMode')}</p>
                  </Box>
                  <Box className='is-txt-small'>{t('choose-adventure.readyMix')}</Box>
                  <ArrowForwardIcon className='text-white right-arrow' />
                </Box>
                <Box className='category-box-container-background'>
                  <img src={guidedHome} alt={t('common.alt.vibeset')} height={400} />
                </Box>
              </Button>
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default ChooseAdventure;
