import { Store } from '@tanstack/store';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const API_URL = import.meta.env.DEV ? '/api/instantSetlist' : 'https://kcdadphd63l5rk5kf33iy3nrjy0zjgkg.lambda-url.us-east-2.on.aws/api/v2/instantSetlist';
const TIDAL_API_URL = import.meta.env.DEV ? '/tidal/convertToTidal' : import.meta.env.VITE_VIBESET_TIDAL_API_URL;
const SPOTIFY_API_URL = import.meta.env.DEV ? '/spotify' : import.meta.env.VITE_VIBESET_SPOTIFY_API_URL;

const sanitizeTrack = (track) => ({
  ...track,
  id: track.id || uuidv4(),
  music_key: track.key,
  key: track.id || uuidv4(), // Use existing id or generate new UUID
});

export const store = new Store({
  mood: null,
  isHarmonicActive: false,
  duration: 30,
  genre: [],
  bpm: 125,
  artist: '',
  song: '',
  autocompleteTags: [],
  setlistResult: null,
  isLoadingSetlist: false,
  error: null,
  sanitizedVibeset: [],
  sanitizedLeftovers: [],
  setlistTitle: '',
  tidalExportLoading: false,
  spotifyExportLoading: false,
  exportError: null,
  tidalPlaylistUrl: null,
  spotifyPlaylistUrl: null,
  setlistModified: false,
});

export const updateSetlistTitle = (title) => {
  store.setState((state) => ({
    ...state,
    setlistTitle: title,
  }));
};

export const updateMood = (mood) => {
  store.setState((state) => ({
    ...state,
    mood: state.mood === mood ? null : mood,
  }));
};

export const toggleHarmonic = () => {
  store.setState((state) => ({
    ...state,
    isHarmonicActive: !state.isHarmonicActive,
  }));
};

export const updateDuration = (duration) => {
  store.setState((state) => ({
    ...state,
    duration,
  }));
};

export const updateGenre = (genre) => {
  store.setState((state) => ({
    ...state,
    genre,
  }));
};

export const updateBpm = (bpm) => {
  store.setState((state) => ({
    ...state,
    bpm,
  }));
};

export const updateArtist = (artist) => {
  store.setState((state) => ({
    ...state,
    artist,
  }));
};

export const updateSong = (song) => {
  store.setState((state) => ({
    ...state,
    song,
  }));
};

export const updateAutocompleteTags = (tags) => {
  store.setState((state) => ({
    ...state,
    autocompleteTags: tags,
  }));
};

export const setSetlistModified = (modified = true) => {
  store.setState((state) => ({
    ...state,
    setlistModified: modified,
    // Reset export-related state when the setlist is modified
    tidalPlaylistUrl: modified ? null : state.tidalPlaylistUrl,
    spotifyPlaylistUrl: modified ? null : state.spotifyPlaylistUrl,
    exportError: null,
  }));
};

export const deleteTrack = (id) => {
  store.setState((state) => {
    const newSanitizedVibeset = state.sanitizedVibeset.filter((track) => track.id !== id);
    return {
      ...state,
      sanitizedVibeset: newSanitizedVibeset,
      setlistModified: true,
    };
  });
};

export const replaceTrack = (id) => {
  store.setState((state) => {
    if (state.sanitizedLeftovers.length === 0) return state;

    const newSanitizedVibeset = [...state.sanitizedVibeset];
    const newSanitizedLeftovers = [...state.sanitizedLeftovers];

    const indexToReplace = newSanitizedVibeset.findIndex((track) => track.id === id);
    if (indexToReplace === -1) return state;

    const bestReplacement = newSanitizedLeftovers.reduce(
      (best, current) => {
        const score = (current.similarity || 0) + (current.popularity || 0) / 100;
        return score > best.score ? { track: current, score } : best;
      },
      { score: -Infinity }
    ).track;

    const replacementIndex = newSanitizedLeftovers.findIndex((track) => track === bestReplacement);

    newSanitizedLeftovers.push(newSanitizedVibeset[indexToReplace]);
    newSanitizedVibeset[indexToReplace] = bestReplacement;
    newSanitizedLeftovers.splice(replacementIndex, 1);

    return {
      ...state,
      sanitizedVibeset: newSanitizedVibeset,
      sanitizedLeftovers: newSanitizedLeftovers,
      setlistModified: true,
    };
  });
};

export const addTrack = () => {
  store.setState((state) => {
    if (state.sanitizedLeftovers.length === 0) return state;

    const newSanitizedVibeset = [...state.sanitizedVibeset];
    const newSanitizedLeftovers = [...state.sanitizedLeftovers];

    const bestAddition = newSanitizedLeftovers.reduce(
      (best, current) => {
        const score = (current.similarity || 0) + (current.popularity || 0) / 100;
        return score > best.score ? { track: current, score } : best;
      },
      { score: -Infinity }
    ).track;

    const additionIndex = newSanitizedLeftovers.findIndex((track) => track === bestAddition);

    newSanitizedVibeset.push(bestAddition);
    newSanitizedLeftovers.splice(additionIndex, 1);

    return {
      ...state,
      sanitizedVibeset: newSanitizedVibeset,
      sanitizedLeftovers: newSanitizedLeftovers,
      setlistModified: true,
    };
  });
};

export const reorderTracks = (draggedId, targetId, position) => {
  store.setState((state) => {
    console.log('Reordering tracks:', { draggedId, targetId, position });

    if (!draggedId || !targetId) {
      console.warn('Invalid draggedId or targetId, no reordering performed');
      return state;
    }

    const newSanitizedVibeset = [...state.sanitizedVibeset];
    const draggedIndex = newSanitizedVibeset.findIndex((track) => track.id === draggedId);
    const targetIndex = newSanitizedVibeset.findIndex((track) => track.id === targetId);

    console.log('Indices:', { draggedIndex, targetIndex });

    if (draggedIndex === -1 || targetIndex === -1) {
      console.warn('Invalid indices, no reordering performed');
      return state;
    }

    // If dropping on itself, do nothing
    if (draggedIndex === targetIndex) {
      console.log('No reordering needed');
      return state;
    }

    // Remove the dragged item
    const [draggedItem] = newSanitizedVibeset.splice(draggedIndex, 1);

    // Calculate the new index
    let newIndex;
    if (position === 'before') {
      newIndex = targetIndex > draggedIndex ? targetIndex - 1 : targetIndex;
    } else {
      // 'after'
      newIndex = targetIndex < draggedIndex ? targetIndex + 1 : targetIndex;
    }

    // Insert the dragged item at the new index
    newSanitizedVibeset.splice(newIndex, 0, draggedItem);

    console.log(
      'New sanitized vibeset order:',
      newSanitizedVibeset.map((track) => track.id)
    );

    return {
      ...state,
      sanitizedVibeset: newSanitizedVibeset,
      setlistModified: true,
    };
  });
};

const safeJsonParse = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
};

export const fetchSetlist = async () => {
  store.setState((state) => ({ ...state, isLoadingSetlist: true, error: null }));

  try {
    const { autocompleteTags, genre, mood, bpm, duration } = store.state;

    const artistNames = [...new Set(autocompleteTags.map((tag) => tag.artist))];
    const songIDs = autocompleteTags
      .filter((tag) => tag.song !== 'N/A' && tag.id)
      .map((tag) => {
        const id = parseInt(tag.id.split('-')[0], 10);
        return isNaN(id) ? null : id;
      })
      .filter((id) => id !== null);

    if (artistNames.length === 0) {
      throw new Error('Please select at least one artist');
    }

    const genreArray =
      Array.isArray(genre) ? genre : (
        genre
          .split(',')
          .map((g) => g.trim())
          .filter((g) => g)
      );

    const payload = {
      artistNames,
      songIDs,
      genre: genreArray,
      mood,
      tempo: bpm,
      setlistLength: duration,
    };

    console.log('Payload sent to API:', JSON.stringify(payload, null, 2));

    const response = await axios.post(API_URL, payload);

    console.log('API Response:', JSON.stringify(response.data, null, 2));

    if (response.data.status === 'success') {
      const parsedSetlist = safeJsonParse(response.data.setlist);
      const parsedLeftovers = safeJsonParse(response.data.leftovers);

      if (!parsedSetlist && !parsedLeftovers) {
        throw new Error('Invalid setlist data received from the server');
      }

      const sanitizedVibeset = (parsedSetlist || []).map(sanitizeTrack);
      const sanitizedLeftovers = (parsedLeftovers || []).map(sanitizeTrack);

      console.log('Sanitized Vibeset:', JSON.stringify(sanitizedVibeset, null, 2));
      console.log('Sanitized Leftovers:', JSON.stringify(sanitizedLeftovers, null, 2));

      store.setState((state) => ({
        ...state,
        setlistResult: {
          vibeset: parsedSetlist || [],
          leftovers: parsedLeftovers || [],
          duration_ms: response.data.duration_ms,
          creation_duration_ms: response.data.creation_duration_ms,
        },
        isLoadingSetlist: false,
        sanitizedVibeset,
        sanitizedLeftovers,
        setlistModified: false,
      }));
    } else {
      throw new Error(response.data.message || 'Failed to generate setlist');
    }
  } catch (error) {
    console.error('Error fetching setlist:', error);
    store.setState((state) => ({
      ...state,
      isLoadingSetlist: false,
      setlistResult: null,
      error: error.message || 'An unexpected error occurred',
    }));
  }
};

export const exportToTidal = async () => {
  store.setState((state) => ({ ...state, tidalExportLoading: true, exportError: null }));
  try {
    const { sanitizedVibeset } = store.state;
    const payload = {
      setlist: sanitizedVibeset.map(({ track, artist_0 }) => ({ name: track, artist: artist_0 })),
    };
    console.log('Exporting to Tidal with payload:', payload);
    const response = await axios.post(TIDAL_API_URL, payload);
    console.log('Tidal export response:', response.data);
    store.setState((state) => ({
      ...state,
      tidalExportLoading: false,
      tidalPlaylistUrl: response.data.result,
      setlistModified: false,
    }));
    console.log('Store updated with Tidal playlist URL:', store.state.tidalPlaylistUrl);
  } catch (error) {
    console.error('Error exporting to Tidal:', error);
    store.setState((state) => ({
      ...state,
      tidalExportLoading: false,
      exportError: 'Failed to export to Tidal',
    }));
  }
};

export const exportToSpotify = async () => {
  store.setState((state) => ({ ...state, spotifyExportLoading: true, exportError: null }));
  try {
    const { sanitizedVibeset } = store.state;
    const payload = {
      setlist: sanitizedVibeset.map((track) => ({
        name: track.track,
        artist: track.artist_0,
      })),
    };
    console.log('Exporting to Spotify with payload:', payload);
    const response = await axios.post(`${SPOTIFY_API_URL}/convertToSpotify`, payload);
    console.log('Spotify export response:', response.data);
    store.setState((state) => ({
      ...state,
      spotifyExportLoading: false,
      spotifyPlaylistUrl: response.data.spotify_url,
      setlistModified: false,
    }));
    console.log('Store updated with Spotify playlist URL:', store.state.spotifyPlaylistUrl);
  } catch (error) {
    console.error('Error exporting to Spotify:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    store.setState((state) => ({
      ...state,
      spotifyExportLoading: false,
      exportError: 'Failed to export to Spotify: ' + (error.response?.data?.message || error.message),
    }));
  }
};
