import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData, login, updateLoginCredential, updateLoader } from '@/redux/slice/login';
import { showToast } from '@/redux/slice/toastSlice';
import * as Form from '@radix-ui/react-form';
import { ArrowRight } from '@phosphor-icons/react';
import createAC from '@/assets/styles/img/welcome-image.png';
import { ACCOUNT, FORGOT } from '@/constants/PageName';
import Ribbon from '@/components/new/Ribbon';
import Container from '@/components/new/Container';
import Title from '@/components/new/Title';
import Lead from '@/components/new/Lead';

function Login() {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.login.formData);
  const errorObject = useSelector((state) => state.login.fieldErrors);

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateLoader(true));
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData);
    const action = await dispatch(login(values));

    const response = action.payload;
    dispatch(updateFormData(values));
    if (response) {
      dispatch(
        showToast({
          message: 'Logged in Successfully!',
          type: 'success',
        })
      );
      dispatch(updateLoginCredential(response));
      dispatch(updateLoader(false));
    } else {
      setTimeout(() => {
        dispatch(updateLoader(false));
      }, 1000);
    }
  };

  return (
    <div className='mt-32 text-white'>
      <section>
        <Container>
          <div className='grid grid-cols-1 items-center gap-16 lg:grid-cols-2'>
            <img className='img-fluid' srcSet={createAC} alt='' role='presentation' />

            <div>
              <div className='mb-12'>
                <Ribbon className='acid-jazz'>
                  <Title size={5} className='mx-auto mb-2 max-w-screen-lg font-semibold'>
                    <h1>Welcome back!</h1>
                  </Title>
                  <Lead size={0}>
                    <p>Login to start creating</p>
                  </Lead>
                </Ribbon>
              </div>

              <Form.Root onSubmit={handleSubmit} className='space-y-6 text-xl'>
                <Form.Field name='email'>
                  <div className='mb-1 flex items-baseline justify-between font-display'>
                    <Form.Label className='text-[1em] text-off-white'>Email</Form.Label>
                    <Form.Message className='text-[0.875em] text-red-500' match='valueMissing'>
                      Please enter your email
                    </Form.Message>
                    <Form.Message className='text-[0.875em] text-red-500' match='typeMismatch'>
                      Please provide a valid email
                    </Form.Message>
                  </div>
                  <Form.Control asChild>
                    <input className='w-full rounded-3 border border-chill-400 bg-transparent px-[1.25em] py-[0.75em] placeholder:text-[1em] placeholder:opacity-50 focus:border-off-white focus:outline-none data-[invalid=true]:ring-2 data-[invalid=true]:ring-red-500' type='email' required placeholder='Email' />
                  </Form.Control>
                  {errorObject?.email && <p className='mt-1 text-[0.875em] text-red-500'>{errorObject.email}</p>}
                </Form.Field>

                <Form.Field name='password'>
                  <div className='mb-1 flex items-baseline gap-4 font-display'>
                    <Form.Label className='mr-auto text-[1em] text-off-white'>Password</Form.Label>
                    <Form.Message className='text-[0.875em] text-red-500' match='valueMissing'>
                      Please enter your password
                    </Form.Message>
                    <Link className='font-display text-[0.875em] text-valence' to={FORGOT}>
                      <span>Forgot?</span>
                    </Link>
                  </div>
                  <Form.Control asChild>
                    <input className='w-full rounded-3 border border-chill-400 bg-transparent px-[1.25em] py-[0.75em] placeholder:text-[1em] placeholder:opacity-50 focus:border-off-white focus:outline-none data-[invalid=true]:ring-2 data-[invalid=true]:ring-red-500' type='password' required placeholder='Password' />
                  </Form.Control>
                  {errorObject?.password && <p className='mt-1 text-[0.875em] text-red-500'>{errorObject.password}</p>}
                </Form.Field>

                <p className='text-danger'>{errorObject.non_field_errors}</p>
                <div className='flex justify-between space-x-4'>
                  <Link to={ACCOUNT} state={{ data: { isNavigatedFromLogin: true } }} className='rounded-3 border-1 border-chill-400 px-[1.25em] py-[0.75em] font-display tracking-wider hover:bg-white/10'>
                    I don&apos;t have an account
                  </Link>
                  <Form.Submit asChild>
                    <button className='flex items-center gap-4 rounded-3 border-1 border-valence bg-valence px-[1.25em] py-[0.75em] font-display font-bold tracking-wider text-hard hover:bg-valence-600'>
                      Login <ArrowRight weight='bold' />
                    </button>
                  </Form.Submit>
                </div>
              </Form.Root>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default Login;
